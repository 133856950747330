import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PortfolioHeader from '../../StaticQueries/PortfolioHeader'
import PortfolioGraphic from '../../StaticQueries/PortfolioGraphic'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


const styles = theme => ({
	paper: {
		maxWidth: 1700,
		margin: 'auto',
		overflow: 'hidden',
		[theme.breakpoints.up('sm')]: {
			minWidth: 600,
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: 936,
		},
	},
	searchBar: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	block: {
		display: 'block',
	},
	addUser: {
		marginRight: theme.spacing.unit,
	},
	contentWrapper: {
		margin: '40px 16px',
		// height: 368,
	},
	container: {
		padding: '48px 36px 48px',
	},
})

function PortfolioContent({ classes }) {
  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.paper}>

          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            {/* <h1 align="center" style={{ color: 'green' }}>-= P°O°R°T°F°O°L°I°O ≡≡≡ S°E°L°E°C°T°I°O°N°S =-</h1> */}
			<PortfolioHeader/>
          </AppBar>

          <div className={classes.contentWrapper}>
				{/* <ContentGraphic /> */}
          </div>

          <div className={classes.contentWrapper} style={{color: 'blue'}}>
            {/* <Typography color="textSecondary" align="justify"> */}
            <typography color="textPrimary" align="justify">
				<PortfolioGraphic/>
				<h2 style={{color: 'green'}}>CATALOG:</h2>   
				<hr/>
				WORK IN PROGRESS...
				<br/>
			</typography>
			{/* </Typography> */}
          </div>
        </Paper>
      </div>
    </>
  )
}

PortfolioContent.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PortfolioContent)
